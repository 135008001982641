export interface StructuredData extends Record<string, any> {
  '@context': string
  '@type':
    | 'FAQPage'
    | 'Organization'
    | 'NewsArticle'
    | 'VideoObject'
    | 'WebPage'
}

export function StructuredData({
  structuredData,
}: {
  structuredData: StructuredData
}) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    />
  )
}
